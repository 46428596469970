//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { getRolePage, sysRoleDelete } from '@/api/modular/system/roleManage'
import addForm from './addForm'
import editForm from './editForm'
import roleMenuForm from './roleMenuForm'
import roleOrgForm from './roleOrgForm'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    roleMenuForm,
    roleOrgForm
  },

  data () {
    return {
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '角色名',
          dataIndex: 'name'
        },
        {
          title: '唯一編碼',
          dataIndex: 'code'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return getRolePage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      }
  }
  },

  created () {
    if (this.hasPerm('sysRole:edit') || this.hasPerm('sysRole:grantMenu') || this.hasPerm('sysRole:grantData') || this.hasPerm('sysRole:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },

  methods: {
    sysRoleDelete (record) {
      sysRoleDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },

    handleOk () {
      this.$refs.table.refresh()
    }
  }

}
